import { useState, createContext, useContext, useMemo } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PresentationModal from '../components/PresentationModal'
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store';

type UploadContext = {
    openUpload: () => void;
    closeUpload: () => void;
};

export const UploadContext = createContext<UploadContext>(
    {} as UploadContext
);

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export const UploadProvider = ({ children }: {children: React.ReactNode}) => {
    const location = useLocation();
    const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(query.get('upload') == 'true');
    const user = useAppSelector((state: RootState) => state.app.user);
    const [searchParams, setSearchParams] = useSearchParams();

    const removeUploadParams = () => {
        const param = searchParams.get('upload');
        if (param) {
            searchParams.delete('upload');
            setSearchParams(searchParams);
        }
    }

    const redirectToAuth = () => {
        location.search = "?upload=true"
        return navigate('auth/login', {state: {from: location}})
    }

    const openUpload = () => {
        if( !user.id ) {
            setOpen(false)
            redirectToAuth()
        } else {
            setOpen(true)
        }
    }

    const closeUpload = () => {
        removeUploadParams()
        setOpen(false)
    }

    return (
        <UploadContext.Provider value={{ openUpload, closeUpload }}
    > {children} {open && <PresentationModal open={open} onClose={closeUpload} />} </UploadContext.Provider>
    );
};

export const useUpload = () => {
    return useContext(UploadContext);
}