import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaginationMeta, User } from "../modules";
import { Link } from "react-router-dom";
import UserBox from "../components/UserBox";
import DataEmptyBox from "../components/DataEmptyBox";
import { useGetCompaniesQuery } from "../api/appApi";

export const Schools = () => {
    const { t, i18n } = useTranslation();
    const [page, setPage] = useState<number>(1);
    const {data: profiles, isFetching: loading} = useGetCompaniesQuery(page);
    const [pagination, setPagination] = useState<PaginationMeta>({current_page: 0} as PaginationMeta);
    const theme = useTheme();

    useEffect(() => {
        // api.getAvailableProfiles(pagination.current_page + 1, 20, 'entreprise').then((rep) => {
        //     setProfiles(rep.data);
        //     setPagination(rep.meta);
        // }).catch(() => {

        // }).finally(() => setLoading(false))
    }, []);
    return (
        <Box sx={{marginBottom: "2rem", maxWidth: "82rem", mx: "auto"}}>
            <Typography variant="h6" sx={{marginBottom: "1rem", fontWeight: "900"}} color={'primary.dark'} children={t('Companies')}/>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                {profiles?.data.map((profile, index) => (
                <Grid item xs={2} sm={4} md={3} lg={2} alignItems="stretch" key={index}>
                    <UserBox {...profile} />
                </Grid>
                ))}
                {!profiles?.data.length && !loading && <DataEmptyBox />}
            </Grid>
        </Box>
    );
}