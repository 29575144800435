import React, { useEffect, useState } from "react";
import { RequireAuth } from "../components/RequireAuth";
import { Avatar, Box, Card, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaginationMeta, Presentation, User } from "../modules";
import { LoadingButton } from "@mui/lab";
import PresentationBox from "../components/PresentationBox";
import UserBox from "../components/UserBox";
import DataEmptyBox from "../components/DataEmptyBox";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { useFollowAuthorMutation, useGetAuthorFollowersQuery, useGetAuthorFollowingsQuery, useGetAuthorPresentationsQuery, useGetAuthorProfileQuery } from "../api/appApi";
import { PartyModeSharp } from "@mui/icons-material";
import { ScrollableFetcher } from "../components/ScrollableFetcher";
import { Helmet } from "react-helmet-async";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`author-tabpanel-${index}`}
            aria-labelledby={`author-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `author-tab-${index}`,
        'aria-controls': `author-tabpanel-${index}`,
    };
}

export const Author = () => {
    const { t, i18n } = useTranslation();
    const user = useAppSelector((state: RootState) => state.app.user);
    const theme = useTheme();
    const params = useParams();
    const slug = params.slug?.replace('@', '');
    const [followAuthor, {isLoading: following}] = useFollowAuthorMutation();
    const {data: author, isFetching: loading} = useGetAuthorProfileQuery(slug as string, {
        skip: !slug
    })
    const [presentationPage, setPresentationPage] = useState(1);
    const [followersPage, setFollowersPage] = useState(1);
    const [subscriptionsPage, setSubscriptionsPage] = useState(1);
    const [tab, setTab] = useState(0);
    const {data: presentations, isFetching: isPresenting} = useGetAuthorPresentationsQuery({page: presentationPage, slug: slug as string}, {
        skip: tab != 0 || !slug
    });
    const {data: followers, isFetching: followersing} = useGetAuthorFollowersQuery({page: followersPage, slug: slug as string}, {
        skip: tab != 1 || !slug
    });
    const {data: subscriptions, isFetching: subscripting} = useGetAuthorFollowingsQuery({page: subscriptionsPage, slug: slug as string}, {
        skip: tab != 2 || !slug
    });

    const toggleTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const follow = () => {
        if ( author )
            followAuthor(author);
    }

    useEffect(() => {
        return () => {
            setTab(0);
            setPresentationPage(1);
            setFollowersPage(1);
            setSubscriptionsPage(1);
        }
    }, [params.slug]);

    if( !loading && !author ) {
        return <DataEmptyBox label={t("This user not exists")} />;
    }

    return (
        <>
            <Helmet>
                <title>{[author?.name, t('app_name')].join(' - ')}</title>
                <meta name="description" content={author?.name} />
            </Helmet>
            <Box sx={{marginBottom: "2rem", maxWidth: "82rem", mx: "auto"}}>
                <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", mx: "-1rem", mt: "-1rem", p:"2rem", bgcolor: "primary.lighter"}}>
                        <Avatar src={author?.avatar || undefined} alt={author?.name} sx={{width: "6rem", height: "6rem", marginInlineEnd: "1.4rem"}}></Avatar>
                        <Box>
                            <Typography variant="h1" sx={{marginBottom: "0.1rem", fontWeight: "600", fontSize: "1.8rem", textTransform: "capitalize"}} color={'black'} children={author?.name}/>
                            <Typography variant="h6" sx={{fontWeight: "400", fontSize: "1rem", textTransform: "capitalize", lineHeight: "1rem"}} color={'black'}>{author?.followers || 0} {t("Followers")}</Typography>
                        </Box>
                        {author && user.slug != author?.slug && <Box sx={{marginInlineStart: "auto"}}>
                            <LoadingButton loading={following || loading} onClick={follow} sx={{minWidth: "6rem"}} variant="contained">{author?.followed ? t("Unfollow") : t("Follow")}</LoadingButton>
                        </Box>}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={toggleTab} aria-label="Author Tabs" scrollButtons="auto">
                                <Tab label={t("Presentations")} {...a11yProps(0)} />
                                <Tab label={t("Followers")} {...a11yProps(1)} />
                                <Tab label={t("Subscriptions")} {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tab} index={0}>
                            <ScrollableFetcher loading={isPresenting} onFetch={() => setPresentationPage((page) => page + 1)} toggle={presentationPage} stop={presentations?.last_page == presentationPage}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                                    {presentations?.data.map((presentation, index) => (
                                        <Grid item xs={4} sm={4} md={3} lg={3} alignItems="stretch" key={index}>
                                            <PresentationBox {...presentation} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </ScrollableFetcher>
                            <DataEmptyBox loading={isPresenting} length={presentations?.data.length}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={1}>
                            <ScrollableFetcher loading={followersing} onFetch={() => setFollowersPage((page) => page + 1)} toggle={followersPage} stop={followers?.last_page == followersPage}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                                    {followers?.data.map((follower, index) => (
                                        <Grid item xs={2} sm={4} md={3} lg={2} alignItems="stretch" key={index}>
                                            <UserBox {...follower} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </ScrollableFetcher>
                            <DataEmptyBox loading={followersing} length={followers?.data.length}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={2}>
                            <ScrollableFetcher loading={subscripting} onFetch={() => setSubscriptionsPage((page) => page + 1)} toggle={subscriptionsPage} stop={subscriptions?.last_page == subscriptionsPage}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                                    {subscriptions?.data.map((follower, index) => (
                                        <Grid item xs={2} sm={4} md={3} lg={2} alignItems="stretch" key={index}>
                                            <UserBox {...follower} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </ScrollableFetcher>
                            <DataEmptyBox loading={subscripting} length={subscriptions?.data.length}/>
                        </CustomTabPanel>
                    </Box>
                </Card>
            </Box>
        </>
    );
}