import { useNavigate, useLocation } from "react-router-dom";
import { Card, CardContent, TextField, Box, Link, Alert, Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import "../css/login.css"
import { useState } from "react";
import AppLink from "../components/AppLink";
import PasswordControl from "../components/PasswordControl";
import { SubmitHandler, useForm } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import { makeCode } from "../helpers";

export const ResetPassword = () => {
    let [code, setCode] = useState('')
    let [otp, setOtp] = useState('')
    let [step, setStep] = useState(0)
    let [loading, setLoading] = useState(false)
    let [error, setError] = useState('')
    let navigate = useNavigate();
    let location = useLocation();
    let { t } = useTranslation();

    const formSchema = {
        email: t("errors.field_required"),
        password: t("errors.field_required"),
        confirm_password: t("errors.field_required"),
    };

    const {
        register: addInput,
        formState: { errors, isSubmitSuccessful },
        setValue: setInput,
        clearErrors: clearInputs,
        getValues: getInput,
        reset,
        setError: setInputError,
        handleSubmit: handleFun,
    } = useForm<Record<any, any>>();

    let from = location.state?.from?.pathname || "/";
    let fromSearch = location.state?.from?.search || "";
    let created = location.state?.created || false

    const requestCode: SubmitHandler<Record<any, any>> = (values) => {
        setLoading(true)
        setError('')
        const rcode = makeCode(6);
        // auth.requestcode(values.email, rcode).then((c) => {
        //     console.log(c)
        //     setCode(rcode);
        //     setStep(1);
        // }).catch((error) => {
        //     if (error.status == 422) {
        //         let resErrors: Array<any> = error.data?.errors
        //         if (typeof resErrors == 'object') {
        //             resErrors.map((err) => {
        //                 let field = err.field
        //                 setInputError(field, {
        //                     type: err.rule,
        //                     message: err.message
        //                 })
        //             })
        //         }
        //     } else if (error.status == 404) {
        //         setInputError('email', {
        //             type: 'invalid',
        //             message: t('errors.email_not_found')
        //         })
        //     }
        // }).finally(() => setLoading(false));
    }

    const verify = (value:string) => {
        if( value == code ) {
            setError('');
            setStep(2)
        } else {
            setError(t("Code is incorrect"))
        }
    }

    const change: SubmitHandler<Record<any, any>> = (values) => {
        setLoading(true)
        setError('')
        if( values.password != values.confirm_password ) {
            setInputError('confirm_password', {
                type: 'password_not_matched',
                message: t("The passwords not matched")
            })
            setLoading(false);
            return;
        }
        // auth.changePassword({...values, code}).then((c) => {
        //     navigate('/auth/login', {state: {changed: true}})
        //     setStep(0);
        // }).catch((error) => {
        //     if (error.status == 422) {
        //         let resErrors: Array<any> = error.data?.errors
        //         if (typeof resErrors == 'object') {
        //             resErrors.map((err) => {
        //                 let field = err.field
        //                 if( field == "code" ) setStep(1);
        //                 setInputError(field, {
        //                     type: err.rule,
        //                     message: err.message
        //                 })
        //             })
        //         }
        //     } else if (error.status == 404) {
        //         setStep(0)
        //         setInputError('email', {
        //             type: 'invalid',
        //             message: t('errors.email_not_found')
        //         })
        //     }
        // }).finally(() => setLoading(false));
    }

    return (
        <div id="login">
            <Card sx={{ maxWidth: 500, borderRadius: "2rem", marginInline: "auto", padding: "2rem" }}>
                <CardContent>
                    <div className="login-card-body">
                        <Box component="h2" sx={{textAlign: "center"}}>{t('Reset your password')}</Box>
                        {!!error && <Alert severity="error" sx={{ marginBottom: "2rem" }}>{error}</Alert>}
                        {created && <Alert severity="success" sx={{ marginBottom: "2rem" }}>{t('Your account has been created, sign in now')}</Alert>}

                        {step == 0 && <>
                            <Box component={'p'} sx={{textAlign: "center"}}>{t('Enter your email address')}</Box>
                            <TextField fullWidth error={!!errors.email} helperText={errors.email?.message?.toString()} id="email" label={t('Email')} variant="outlined" type="email" style={{ marginBottom: "1.6rem" }} {...addInput('email', { required: formSchema.email })} />
                            <LoadingButton fullWidth size="large" color="primary" onClick={handleFun(requestCode)} loading={loading} variant="contained">{t('Next')}</LoadingButton>
                        </>}

                        {step == 1 && <>
                            <Box component={'p'} sx={{textAlign: "center"}}>{t('Enter verification code')}</Box>
                            <MuiOtpInput length={6} value={otp} onChange={setOtp} onComplete={verify} />
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button variant="outlined" color="inherit" onClick={() => setStep(0)} sx={{ mr: 1 }}>{t('Back')}</Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                            </Box>
                        </>}

                        {step == 2 && <>
                            <Box component={'p'} sx={{textAlign: "center"}}>{t('Choose a new password')}</Box>
                            <PasswordControl fullWidth error={!!errors.password} helperText={errors.password?.message?.toString()} {...addInput('password', { required: formSchema.password })} onChange={(e) => setInput('password', e.target.value)} id="password" label={t('Password')} sx={{ marginBottom: "1.6rem" }} ></PasswordControl>
                            <PasswordControl fullWidth error={!!errors.confirm_password} helperText={errors.confirm_password?.message?.toString()} {...addInput('confirm_password', { required: formSchema.confirm_password })} onChange={(e) => setInput('confirm_password', e.target.value)} id="confirm_password" label={t('Confirm password')} sx={{ marginBottom: "1.6rem" }} ></PasswordControl>
                            <LoadingButton fullWidth size="large" color="primary" onClick={handleFun(change)} loading={loading} variant="contained">{t('Change your password')}</LoadingButton>
                        </>}

                        <hr style={{ marginBlock: "2rem" }} />
                        <Box sx={{ textAlign: 'center' }}>
                            <span style={{ marginInlineEnd: "0.2rem" }}>{t("You don't have an account?")}</span>
                            <AppLink to="/auth/signup" color={'primary'} state={{ from: location.state?.from }}>{t('Sign up')}</AppLink>
                        </Box>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}