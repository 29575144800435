import { Avatar, Box, Button, Link, Stack, Typography, useTheme } from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import { Author } from "../modules";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { useTranslation } from "react-i18next";
import { useFollowAuthorMutation } from "../api/appApi";

type AuthorLinkProps = Author & {pub?: string};

const AuthorLink = (author: AuthorLinkProps) => {
    const user = useAppSelector((state: RootState) => state.app.user);
    const [followAuthor, {isLoading}] = useFollowAuthorMutation();
    const theme = useTheme();
    const {t} = useTranslation();
    const follow = () => {
        followAuthor(author)
    }
    return (
        <Stack sx={{
            borderRadius: "2rem",
            //border: `1px solid ${theme.palette.primary.main}`,
            flexDirection: "row",
            alignItems: "center",
            bgcolor: "rgba(0,0,0,0.15)",
            p: "0.3rem",
            height: "2.6rem",
            display: "inline-flex",
            gap: "0.4rem"
        }}>
                <Link component={RouterLink} to={`/u/@${author.slug}`} >
                    <Avatar sx={{width: "2rem", height: "2rem", fontSize: "1rem"}} alt={author.name} src={author.avatar || undefined}/>
                </Link>
                <Stack sx={{flexDirection: "column", alignItems: "start"}}>
                    <Link component={RouterLink} to={`/u/@${author.slug}`} sx={{fontSize: "0.8rem", paddingInlineStart: 0, display: "inline-flex", alignItems: "center", justifyContent: "center", position: "relative", textDecoration: "none", textTransform: "capitalize"}}>
                        <Typography sx={{fontWeight: "800", lineHeight: "1"}} variant="body2" color={'primary'}>{!!author.name ? author.name : `@${author.slug}`}</Typography>
                    </Link>
                    <Box sx={{fontSize: "0.8rem", lineHeight: "1"}}>{author.followers} {t("Followers")}</Box>
                </Stack>
                <Box>
                    {user.slug == author.slug ? <Button component={RouterLink} variant="contained" size="small" sx={{borderRadius: "2rem"}} to={`/u/@${author.slug}`} disableElevation>{t("View presenter")}</Button> : <Button variant="contained" size="small" sx={{borderRadius: "2rem"}} disableElevation onClick={follow} disabled={isLoading}>{author.followed ? t("Unfollow") : t("Follow")}</Button>}
                </Box>
        </Stack>
    )
}

export default AuthorLink;