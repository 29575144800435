import { useRef, useState } from 'react';

import { Link, NavLink, useLocation } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { Settings, Person } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { useAppSelector } from '../../store/hooks';
import { useLogoutMutation } from '../../api/appApi';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.palette.appgrey?.light};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.primary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.primary.light}
`
);

function ProfileSection() {
  const theme = useTheme();
  const user = useAppSelector((state: RootState) => state.app.user);
  const [logout] = useLogoutMutation();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  let location = useLocation();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignout = () => {
    handleClose()
    logout()
  }

  if (!user.id) {
    return <Button component={Link} variant="outlined" to={'auth/login'} state={{ from: location }}>{t('Login')}</Button>
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="circular" sx={{ bgcolor: 'primary.light', ...theme.typography.mediumAvatar }} alt={user.name} src={user.avatar || undefined} />
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="circular" sx={{ bgcolor: 'primary.light' }} alt={user.name} src={user.avatar || undefined} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user.email}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton to="/me" component={NavLink} onClick={handleClose}>
            <Person fontSize="small" sx={{ marginInlineEnd: "0.6rem" }} />
            <ListItemText primary={t("My Profile")} />
          </ListItemButton>
          <ListItemButton to="/me/settings" component={NavLink} onClick={handleClose} >
            <Settings fontSize="small" sx={{ marginInlineEnd: "0.6rem" }} />
            <ListItemText primary={t('My settings')} />
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" onClick={handleSignout} fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default ProfileSection;