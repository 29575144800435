import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Presentation as Model } from "../modules";
import { Box, Card, Grid, Button, Typography, useTheme, Avatar, Collapse, TextField, Divider, Link as MuiLink } from "@mui/material";
import VideoPlayer from "../components/VideoPlayer";
import { BookmarkAddOutlined, BookmarkRounded, ChatBubbleOutlineRounded, FavoriteBorderOutlined, FavoriteRounded, VisibilityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import PresentationBox from "../components/PresentationBox";
import { Helmet } from "react-helmet-async";
import PresentationItem from "../components/PresentationItem";
import Moment from "react-moment";
import { parseStringHtml } from "../helpers";
import AuthorLink from "../components/AuthorLink";
import CommentSection from "../components/CommentSection";
import DataEmptyBox from "../components/DataEmptyBox";
import { RootState } from "../store";
import { useAppSelector } from "../store/hooks";
import { useCarePublicationMutation, useGetPublicationQuery, useLogPublicationMutation, useSavePublicationMutation } from "../api/appApi";
import { SidePresentations } from "../components/SidePresentations";

export const Presentation = () => {
    const user = useAppSelector((state: RootState) => state.app.user);
    const [presentations, setPresentations] = useState<Array<Model>>([]);
    const [collapsed, setCollapsed] = useState(true);
    const [watch,] = useState({
        step: -1,
        logging: false
    });
    const { t, i18n } = useTranslation();
    const params = useParams();
    const theme = useTheme();
    const {data: presentation, isLoading: loading} = useGetPublicationQuery(params.slug, {
        skip: !params.slug
    });
    const [logPresentation] = useLogPublicationMutation();
    const [carePresentation] = useCarePublicationMutation();
    const [savePresentation] = useSavePublicationMutation();

    const care = () => {
        if( presentation?.slug ) {
            carePresentation(presentation)
        }
    }

    const save = () => {
        if( presentation?.slug ) {
            savePresentation(presentation)
        }
    }

    const timeUpdate = (time: number, duration: number, id?: any) => {
        if( !user.id ) {
           // return;
        }
        if (watch.logging) return;
        if( !presentation?.slug ) return;
        let valide = (20 * duration) / 100
        let steps = Array.from(
            { length: (duration - valide) / valide + 1 },
            (value, index) => valide + index * valide
        );
        if (watch.step == -1) {
            let _valide = Math.round(valide > 30 ? 30 : valide);
            let _time = Math.round(time);
            if (_time >= _valide) {
                watch.logging = true;
                logPresentation({slug: presentation?.slug, type: 'view', meta: _valide}).unwrap().then((data: any) => {
                    watch.step = 0;
                }).catch(() => {}).finally(() => watch.logging = false)
            }
        } else if (duration == time) {
            watch.logging = true;
            logPresentation({slug: presentation?.slug, type: 'watchEnd', meta: time}).unwrap().then((data: any) => {
                watch.step = 0;
            }).catch(() => {}).finally(() => watch.logging = false)
        } else if (steps[watch.step] && time >= steps[watch.step]) {
            watch.logging = true;
            logPresentation({slug: presentation?.slug, type: 'watch', meta: time}).unwrap().then((data: any) => {
                watch.step = watch.step + 1;
            }).catch(() => {}).finally(() => watch.logging = false)
        }
    }

    const onReady = (player: any) => {
        if( player ) {
            player.on('timeupdate', (e:any) => {
                timeUpdate(player.currentTime(), player.duration());
            })
        }
    }

    useEffect(() => {
        
    }, [params.slug]);

    if( !loading && !presentation?.slug ) {
        return <DataEmptyBox label={t("This presentation not exists")} />;
    }

    return (
        <>
            <Helmet>
                <title>{[presentation?.title, t('app_name')].join(' - ')}</title>
            </Helmet>
            <Box sx={{width: "100%", backgroundRepeat: 'no-repeat', backgroundPosition: 'top center, bottom center', backgroundSize: '100% auto, 100% auto'}}>
                <Box sx={{maxWidth: "82rem", marginInline: "auto"}}>
                    <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
                                <Box sx={{overflow: "hidden", borderRadius: theme.borders.primary}}>
                                    {!!presentation?.slug && <VideoPlayer onReady={onReady} options={{autoplay: true, poster: presentation.image_url, aspectRatio: "16:9"}} src={presentation.video_url} />}
                                </Box>
                                <Box sx={{marginTop: "1rem", marginBottom: "1rem"}}>
                                    <Typography variant="h6" sx={{fontSize: "1rem", fontWeight: 'bold', color: 'black'}}>{presentation?.title}</Typography>
                                </Box>
                                <Box sx={{marginBottom: "1rem"}}>
                                    {!!presentation?.author && <AuthorLink pub={presentation.slug} {...presentation?.author}/>}
                                </Box>
                                <Box sx={{display: "flex", flexDirection: "row"}}>
                                    <Button onClick={care} variant="text" startIcon={presentation?.currentcare ? <FavoriteRounded /> : <FavoriteBorderOutlined />}>{t('Care')}</Button>
                                    <Button onClick={save} variant="text" startIcon={presentation?.currentsave ? <BookmarkRounded /> : <BookmarkAddOutlined />}>{t('Save')}</Button>
                                </Box>
                            </Card>
                            <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
                                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "1rem"}}>
                                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <VisibilityOutlined sx={{fontSize: "0.8rem", marginInlineEnd: "0.4rem"}} />
                                        <Typography sx={{fontSize: "0.8rem"}}>{presentation?.views} {t("views")}</Typography>
                                    </Box>
                                    <Box sx={{display: "block", width: "0.3rem", height: "0.3rem", borderRadius: "100%", backgroundColor: "primary.dark", marginInline: "0.4rem", flex: "0 0 auto"}}/>
                                    <Moment style={{fontSize: "0.8rem"}} fromNow locale={i18n.language}>{presentation?.created_at}</Moment>
                                </Box>
                                <Collapse in={!collapsed} collapsedSize={40} >
                                    {parseStringHtml(presentation?.description)}
                                </Collapse>
                                <Box>
                                    <MuiLink onClick={() => setCollapsed((value) => !value)}>{collapsed ? t('More') : t('Less')}</MuiLink>
                                </Box>
                            </Card>
                            {!!presentation?.slug && <CommentSection key={`comment-section-${presentation?.slug}`} author={presentation?.author?.slug} slug={presentation?.slug} />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "2rem", borderRadius: theme.borders.primary}}>
                                {!!presentation?.slug && <SidePresentations slug={presentation.slug}/>}
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}