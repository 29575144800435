import { Avatar, Box, Button, Stack, SxProps } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { uploadToAws } from "../services/VideoService";

export interface AvatarUploaderProps {
    src?: any;
    alt?: any;
    onUpload?: (file: string) => void;
    sx?: SxProps;
}

export const AvatarUploader = (props: AvatarUploaderProps) => {
    const inputRef = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const { t } = useTranslation();

    const handleChange = (event: any) => {
        setError('');
        setSuccess('');
        const file = event.target.files && event.target.files[0];
        if ( !file ) {
            return;
        }

        event.target.value = null;

        setLoading(true);
        uploadToAws([file]).then((d) => {
            props.onUpload && props.onUpload(d.file);
            setSuccess(t('file_has_been_uploaded'))
        }).catch(() => {
            setError(t('failed_upload_file'))
        }).finally(() => setLoading(false))
    }

    return (<>
        <Stack sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "2rem",
            ...props.sx
        }}>
            <Avatar
                alt={props.alt || ''}
                src={props.src || ''}
                sx={{ width: "6rem", height: "6rem" }}
            />
            <Box sx={{
                display: "flex",
                flexDirection: "column"
            }}>
                <LoadingButton variant="outlined" onClick={() => inputRef.current && inputRef.current.click()} loading={loading}>{t('upload_file')}</LoadingButton>
                {!!error && <Box sx={{color: "redcolor.main", fontSize: "0.8rem", fontWeight: "600"}}>{error}</Box>}
                {!!success && <Box sx={{color: "greencolor.main", fontSize: "0.8rem", fontWeight: "600"}}>{success}</Box>}
                <Box component={'input'} type="file" accept="image/png,image/jpg,image/jpeg,image/webp" hidden ref={inputRef} onChange={handleChange}></Box>
            </Box>
        </Stack>
    </>)
}