import { Autocomplete, AutocompleteProps, ChipTypeMap } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { useTranslable } from "../helpers";

type ActivitySelectProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, "options">;

export default function ActivitySelect<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(props: ActivitySelectProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
    const activities = useAppSelector((state: RootState) => state.app.activities);
    const {ts} = useTranslable();
    return (
        <Autocomplete {...props} options={activities.map((activ) => (activ.id as any))} getOptionLabel={(option) => ts(activities.find((act) => act.id == option)?.name || '')}/>
    );
}