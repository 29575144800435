import { useTheme } from '@mui/material/styles';
import React, { useEffect } from "react";
import { useGetAllActivitiesQuery, useGetCurrentUserQuery } from '../api/appApi';
import { useAppDispatch } from '../store/hooks';
import { setActivities } from '../store/appReducer';
import { LoadingScreen } from '../components/LoadingScreen';

export const WrapLayout = ({children} : {children: React.ReactNode}) => {
    const dispatch = useAppDispatch();
    const {isLoading: loading} = useGetCurrentUserQuery();
    const {data: activities} = useGetAllActivitiesQuery(undefined, {skip: loading});
    const theme = useTheme();
    useEffect(() => {
        if( activities ) {
            dispatch(setActivities(activities));
        }
    }, [activities])

    if(loading) {
        return <LoadingScreen />;
    }

    return <>{children}</>;
}