import { createSlice } from '@reduxjs/toolkit'
import { Activity, Auth } from '../modules';

interface AppState {
    user: Auth;
    loading: boolean;
    menuOpen: boolean;
    modal: boolean;
    activities: Activity[]
}

// Define the initial state using that type
const initialState: AppState = {
    user: {} as Auth,
    loading: true,
    menuOpen: false,
    modal: false,
    activities: []
};

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        openMenu(state) {
            state.menuOpen = true;
        },
        closeMenu(state) {
            state.menuOpen = false;
        },
        toggleMenu(state) {
            state.menuOpen = !state.menuOpen;
        },
        setLocalModal(state, action) {
            state.modal = action.payload;
        },
        setActivities(state, action) {
            state.activities = action.payload;
        },
        logout(state) {
            state.user = {} as Auth;
        }
    }
})

export const {
    setUser, setLoading, openMenu, closeMenu, toggleMenu, setLocalModal, logout,
    setActivities
} = appSlice.actions
export default appSlice.reducer