import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL, AUTH_KEY } from '../store/constant'
import { Activity, Comment, ListResponse, LogArgs, Publication, Auth, Author, SignUser, EditablePublication } from '../modules'
import { RootState } from '../store';
import { logout, setUser } from '../store/appReducer';

interface ReAuth {
    included: boolean;
    refresh: boolean;
}

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    validateStatus: (response, body) => response.status === 200 && body.status < 400,
    prepareHeaders: (headers, { getState } ) => {
        headers.set('Accept', 'application/json');
        headers.set('X-AUTH', AUTH_KEY);
    },
    credentials: "include"
})

const parseResponse = (result: any, reauth: ReAuth = {included: false, refresh: false}) => {
    if (result.error) {
        reauth.refresh = (result.error.data as any).refresh;
        reauth.included = (result.error.data as any).included;
        result.error.status = (result.error.data as any).status;
        result.error.data = (result.error.data as any).payload;
    } else if(result.data) {
        reauth.refresh = (result.data as any).refresh;
        reauth.included = (result.data as any).included;
        result.data = (result.data as any).payload;
    }
    return result;
}

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let reauth = {included: false, refresh: false}
    let result = await baseQuery(args, api, extraOptions)
    result = parseResponse(result, reauth);
    if ((result.error && result.error.status === 401) || reauth.refresh) {
        if( reauth.included ) {
            let refreshResult = await baseQuery({url: '/auth/refresh', method: "POST"}, api, extraOptions)
            refreshResult = parseResponse(refreshResult, reauth);
            if (refreshResult.data) {
                let userResult = await baseQuery('auth/current', api, extraOptions)
                userResult = parseResponse(userResult, reauth);
                api.dispatch(setUser(userResult.data));
                result = await baseQuery(args, api, extraOptions)
                result = parseResponse(result, reauth);
            } else {
                await baseQuery({url: '/auth/logout', method: "POST"}, api, extraOptions)
                api.dispatch(logout())
            }
        } else {
            api.dispatch(logout())
        }
    }
    return result
}

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Publications', 'Publication', 'Comments', 'Follows', 'Replies', 'Creators'],
    endpoints: (builder) => ({
        login: builder.mutation<string, {email: string, password: string}>({
            query: (login) => ({
                url: 'auth/login',
                method: 'POST',
                body: login
            }),
            invalidatesTags: ['Publications', 'Publication', 'Comments', 'Follows', 'Replies', 'Creators'],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    await dispatch(appApi.endpoints.getCurrentUser.initiate(undefined, {forceRefetch: true}));
                } catch(error) {}
            },
        }),
        signup: builder.mutation<string, SignUser>({
            query: (login) => ({
                url: 'auth/signup',
                method: 'POST',
                body: login
            }),
        }),
        logout: builder.mutation<string, void>({
            query: () => ({
                url: 'auth/logout',
                method: 'POST'
            }),
            invalidatesTags: ['Publications', 'Publication', 'Comments', 'Follows', 'Replies', 'Creators'],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                    dispatch(logout());
                } catch(error) {}
            },
        }),
        updateProfile: builder.mutation<Auth, Pick<Auth, 'name' | 'activity_id' | 'avatar'>>({
            query: ({name, activity_id, avatar}) => ({
                url: 'my/profile/update',
                method: 'POST',
                body: {name, activity_id, avatar}
            }),
            invalidatesTags: ['Publications', 'Publication', 'Comments', 'Follows', 'Replies', 'Creators'],
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setUser(data));
                } catch(error) {}
            },
        }),
        getCurrentUser: builder.query<Auth, void>({
            query: () => 'auth/current',
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setUser(data));
                } catch (error) {}
            },
        }),
        getAllActivities: builder.query<Activity[], void>({
            query: () => 'activities/all'
        }),
        getHomePublications: builder.query<ListResponse<Publication>, number | void>({
            query: (page = 1) => `publications?page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Publications' as const, id: slug })),
                { type: 'Publications', id: 'LIST' },
            ] : [{ type: 'Publications', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getSidePublications: builder.query<ListResponse<Publication>, {page: number, slug: string}>({
            query: ({page = 1, slug}) => `publications/side?page=${page}&slug=${slug}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Publications' as const, id: slug })),
                { type: 'Publications', id: 'LIST' },
            ] : [{ type: 'Publications', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}-${queryArgs.slug}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        uploadPublication: builder.mutation<string, EditablePublication>({
            query: (body) => ({
                url: 'publications/upload',
                method: "POST",
                body
            })
        }),
        getPublication: builder.query<Publication, string | undefined>({
            query: (slug) => `publications/get?slug=${slug}`,
            providesTags: (result, error, page) =>
            result ? [
                { type: 'Publication' as const, id: result.slug },
                { type: 'Publication', id: 'ITEM' },
            ] : [{ type: 'Publication', id: 'ITEM' }],
        }),
        logPublication: builder.mutation<string, LogArgs>({
            query: (body) => ({
                url: 'publications/log',
                method: "POST",
                body
            })
        }),
        carePublication: builder.mutation<string, Pick<Publication, 'slug'>>({
            query: ({slug}) => ({
                url: 'publications/care',
                method: "POST",
                body: {slug}
            }),
            async onQueryStarted({ slug }, { dispatch, queryFulfilled }) {
                const action = dispatch(
                    appApi.util.updateQueryData('getPublication', slug, (draft) => {
                        draft.cares = draft.currentcare ? draft.cares - 1 : draft.cares + 1;
                        draft.currentcare = !draft.currentcare;
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    action.undo()
                }
            },
        }),
        savePublication: builder.mutation<string, Pick<Publication, 'slug'>>({
            query: ({slug}) => ({
                url: 'publications/save',
                method: "POST",
                body: {slug}
            }),
            async onQueryStarted({ slug }, { dispatch, queryFulfilled }) {
                const action = dispatch(
                    appApi.util.updateQueryData('getPublication', slug, (draft) => {
                        draft.saves = draft.currentcare ? draft.saves - 1 : draft.saves + 1;
                        draft.currentsave = !draft.currentsave;
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    action.undo()
                }
            },
        }),
        commentPublication: builder.mutation<Comment, {slug: string, content: string}>({
            query: ({slug, content}) => ({
                url: 'comments/add',
                method: "POST",
                body: {slug, content}
            }),
            invalidatesTags: [
                { type: 'Comments', id: "LIST" }
            ]
        }),
        replyComment: builder.mutation<Comment, Pick<Comment, 'id'> & {slug: string, content: string}>({
            query: ({id, slug, content}) => ({
                url: 'comments/add',
                method: "POST",
                body: {slug, content, comment_id: id}
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Replies', id: `LIST${arg.id}` },
                { type: "Comments", id: arg.id }
            ]
        }),
        getComments: builder.query<ListResponse<Comment>, {slug: string, page?: number, sort?: string}>({
            query: ({slug, page = 1, sort = 'desc'}) => `comments/load?slug=${slug}&page=${page}&sort=${sort}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ id }) => ({ type: 'Comments' as const, id })),
                { type: 'Comments', id: 'LIST' },
            ] : [{ type: 'Comments', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}-${queryArgs.slug}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                if( args.arg.page == 1 )
                    currentCache.data = data;
                else
                    currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getReplies: builder.query<ListResponse<Comment>, Pick<Comment, 'id'> & {slug: string, page?: number, sort?: string}>({
            query: ({id, slug, page = 1, sort = 'desc'}) => `comments/replies?comment_id=${id}&slug=${slug}&page=${page}&sort=${sort}&per_page=10`,
            providesTags: (result, error, arg) =>
            result ? [
                ...result.data.map(({ id }) => ({ type: 'Replies' as const, id })),
                { type: 'Replies', id: `LIST${arg.id}` },
            ] : [{ type: 'Replies', id: `LIST${arg.id}` }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}-${queryArgs.id}-${queryArgs.slug}`;
            },
            merge: (currentCache, newItems, args) => {
                if( args.arg.page == 1 )
                    currentCache.data = newItems.data;
                else
                    currentCache.data.push(...newItems.data);
                currentCache.current_page = newItems.current_page;
                currentCache.from = newItems.from;
                currentCache.last_page = newItems.last_page;
                currentCache.path = newItems.path;
                currentCache.per_page = newItems.per_page;
                currentCache.to = newItems.to;
                currentCache.total = newItems.total;
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        editComment: builder.mutation<Comment, Pick<Comment, 'id' | 'comment_id'> & {comment: string}>({
            query: ({id, comment}) => ({
                url: 'comments/edit',
                method: "POST",
                body: {id, content: comment}
            }),
            invalidatesTags: (result, error, arg) => arg.comment_id ? [
                { type: "Replies", id: arg.id },
                { type: 'Replies', id: `LIST${arg.comment_id}` }
            ] : [
                { type: "Comments", id: arg.id },
                { type: 'Comments', id: "LIST" }
            ]
        }),
        deleteComment: builder.mutation<string, Pick<Comment, 'id' | 'comment_id'>>({
            query: ({id}) => ({
                url: 'comments/delete',
                method: "POST",
                body: {id}
            }),
            invalidatesTags: (result, error, arg) => arg.comment_id ? [
                { type: "Replies", id: arg.id },
                { type: 'Replies', id: `LIST${arg.comment_id}` }
            ] : [
                { type: 'Comments', id: "LIST" }
            ]
        }),
        followAuthor: builder.mutation<Author, Pick<Author, 'slug'> & {pub?: string}>({
            query: ({slug, pub}) => ({
                url: 'follows/save',
                method: "POST",
                body: {slug}
            }),
            invalidatesTags: [
                { type: 'Publication', id: "ITEM" },
                { type: 'Creators', id: 'ITEM' }
            ]
        }),
        getCompanies: builder.query<ListResponse<Author>, number | void>({
            query: (page = 1) => `creators/companies?page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Creators' as const, id: slug })),
                { type: 'Creators', id: 'LIST' },
            ] : [{ type: 'Creators', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getMyPresentations: builder.query<ListResponse<Publication>, number | void>({
            query: (page = 1) => `my/presentations?page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Publications' as const, id: slug })),
                { type: 'Publications', id: 'LIST' },
            ] : [{ type: 'Publications', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getMyFollowers: builder.query<ListResponse<Author>, number | void>({
            query: (page = 1) => `my/followers?page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Creators' as const, id: slug })),
                { type: 'Creators', id: 'LIST' },
            ] : [{ type: 'Creators', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getMyFollowings: builder.query<ListResponse<Author>, number | void>({
            query: (page = 1) => `my/followings?page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Creators' as const, id: slug })),
                { type: 'Creators', id: 'LIST' },
            ] : [{ type: 'Creators', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getMyLibrary: builder.query<ListResponse<Publication>, number | void>({
            query: (page = 1) => `my/saves?page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Publications' as const, id: slug })),
                { type: 'Publications', id: 'LIST' },
            ] : [{ type: 'Publications', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getAuthorProfile: builder.query<Author, string>({
            query: (slug) => `creators/profile?slug=${slug}`,
            providesTags: (result, error, arg) =>
            result ? [
                { type: 'Creators' as const, id: result.slug },
                { type: 'Creators', id: 'ITEM' },
            ] : [{ type: 'Creators', id: 'ITEM' }]
        }),
        getAuthorPresentations: builder.query<ListResponse<Publication>, {page: number, slug: string}>({
            query: ({page = 1, slug}) => `creators/presentations?slug=${slug}&page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Publications' as const, id: slug })),
                { type: 'Publications', id: 'LIST' },
            ] : [{ type: 'Publications', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}-${queryArgs.slug}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getAuthorFollowers: builder.query<ListResponse<Author>, {page: number, slug: string}>({
            query: ({page = 1, slug}) => `creators/followers?slug=${slug}&page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Creators' as const, id: slug })),
                { type: 'Creators', id: 'LIST' },
            ] : [{ type: 'Creators', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}-${queryArgs.slug}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getAuthorFollowings: builder.query<ListResponse<Author>, {page: number, slug: string}>({
            query: ({page = 1, slug}) => `creators/followings?slug=${slug}&page=${page}`,
            providesTags: (result, error, page) =>
            result ? [
                ...result.data.map(({ slug }) => ({ type: 'Creators' as const, id: slug })),
                { type: 'Creators', id: 'LIST' },
            ] : [{ type: 'Creators', id: 'LIST' }],
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return `${endpointName}-${queryArgs.slug}`;
            },
            merge: (currentCache, newItems, args) => {
                const {data, ...obj} = newItems;
                currentCache.data.push(...data);
                Object.assign(currentCache, obj);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
    }),
})

export const { useGetAllActivitiesQuery, useLoginMutation, useGetHomePublicationsQuery, useGetPublicationQuery, useLogPublicationMutation, useGetCommentsQuery, useGetCurrentUserQuery, useCarePublicationMutation, useSavePublicationMutation, useCommentPublicationMutation, useDeleteCommentMutation, useFollowAuthorMutation, useLogoutMutation, useEditCommentMutation, useReplyCommentMutation, useGetRepliesQuery, useGetSidePublicationsQuery, useSignupMutation, useUploadPublicationMutation, useGetCompaniesQuery, useGetMyPresentationsQuery, useGetMyFollowersQuery, useGetMyFollowingsQuery, useGetMyLibraryQuery, useGetAuthorFollowersQuery, useGetAuthorFollowingsQuery, useGetAuthorPresentationsQuery, useGetAuthorProfileQuery, useUpdateProfileMutation } = appApi