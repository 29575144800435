import { configureStore } from '@reduxjs/toolkit'
import appReducer from './appReducer'
import { appApi } from '../api/appApi'
import { setupListeners } from '@reduxjs/toolkit/query';
import customizationReducer from './customizationReducer';

export const store = configureStore({
    reducer: {
        app: appReducer,
        customization: customizationReducer,
        [appApi.reducerPath]: appApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appApi.middleware),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch