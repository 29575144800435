import React, { useEffect, useState } from "react";
import { RequireAuth } from "../components/RequireAuth";
import { Avatar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Auth, PaginationMeta, Presentation, User } from "../modules";
import { LoadingButton } from "@mui/lab";
import PresentationBox from "../components/PresentationBox";
import UserBox from "../components/UserBox";
import DataEmptyBox from "../components/DataEmptyBox";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { useGetMyFollowersQuery, useGetMyFollowingsQuery, useGetMyLibraryQuery, useGetMyPresentationsQuery, useUpdateProfileMutation } from "../api/appApi";
import { ScrollableFetcher } from "../components/ScrollableFetcher";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import ActivitySelect from "../components/ActivitySelect";
import { AvatarUploader } from "../components/AvatarUploader";
import { handleEntityErrors } from "../helpers";
import { Helmet } from "react-helmet-async";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`author-tabpanel-${index}`}
            aria-labelledby={`author-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `author-tab-${index}`,
        'aria-controls': `author-tabpanel-${index}`,
    };
}

export const Profile = () => {
    const { t, i18n } = useTranslation();
    const user = useAppSelector((state: RootState) => state.app.user);
    const theme = useTheme();
    const params = useParams();
    const [presentationPage, setPresentationPage] = useState(1);
    const [followersPage, setFollowersPage] = useState(1);
    const [subscriptionsPage, setSubscriptionsPage] = useState(1);
    const [libraryPage, setLibraryPage] = useState(1);
    const [tab, setTab] = useState(0);
    const [editmode, setEditMode] = useState(false);
    const {data: presentations, isFetching: isPresenting} = useGetMyPresentationsQuery(presentationPage, {
        skip: tab != 0
    });
    const {data: followers, isFetching: followersing} = useGetMyFollowersQuery(followersPage, {
        skip: tab != 1
    });
    const {data: subscriptions, isFetching: subscripting} = useGetMyFollowingsQuery(subscriptionsPage, {
        skip: tab != 2
    });
    const {data: library, isFetching: liLoading} = useGetMyLibraryQuery(libraryPage, {
        skip: tab != 3
    });
    const [updateProfile, {isLoading: updating}] = useUpdateProfileMutation();
    const navigate = useNavigate();

    const formSchema = {
        name: t("errors.invalid_name"),
        gender: t("errors.field_required"),
        activity_id: t("errors.field_required"),
        director_name: t("errors.field_required"),
        email: t("errors.field_required"),
        phone: t("errors.field_required"),
        password: t("errors.field_required"),
    };

    const {
        register: personal,
        formState: { errors, isSubmitSuccessful },
        setValue: setPersonal,
        clearErrors: clearPersonals,
        getValues: getPersonalInfo,
        reset,
        setError: setPersonalError,
        handleSubmit: handleUpdate,
        watch: liveWatch
    } = useForm<Auth>({
        defaultValues: user
    });

    const update: SubmitHandler<Auth> = (values) => {
        updateProfile(values).unwrap().then(() => {
            setEditMode(false);
        }).catch((error) => {
            if( error.status == 422 ) {
              handleEntityErrors(error.data, setPersonalError);
            }
        })
    }

    const toggleTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const closeEditMode = () => {
        setEditMode(false);
    }

    useEffect(() => {
        if(params.tab == 'library') {
            setTab(3);
        }
        return () => {
            setTab(0);
        }
    }, [params.tab]);

    useEffect(() => {
        return () => {
            setTab(0);
        }
    }, [user.slug]);

    return (
        <>
            <Helmet>
                <title>{[user?.name, t('app_name')].join(' - ')}</title>
                <meta name="description" content={user?.name} />
            </Helmet>
            <Box sx={{ marginBottom: "2rem", maxWidth: "82rem", mx: "auto" }}>
                <Card sx={{ maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mx: "-1rem", mt: "-1rem", p: "2rem", bgcolor: "primary.lighter" }}>
                        <Avatar src={user.avatar || undefined} alt={user.name} sx={{ width: "6rem", height: "6rem", marginInlineEnd: "1.4rem" }}></Avatar>
                        <Box>
                            <Typography variant="h1" sx={{ marginBottom: "0.1rem", fontWeight: "600", fontSize: "1.8rem", textTransform: "capitalize" }} color={'black'} children={user.name} />
                            <Typography variant="h6" sx={{ fontWeight: "400", fontSize: "1rem", textTransform: "capitalize", lineHeight: "1rem" }} color={'black'}>{user.followers} {t("Followers")}</Typography>
                        </Box>
                        <Box sx={{marginInlineStart: "auto"}}>
                            <Button onClick={() => setEditMode(true)} sx={{minWidth: "6rem"}} variant="contained">{t("Edit profile")}</Button>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={toggleTab} aria-label="Profile tabs" variant="scrollable" scrollButtons="auto">
                                <Tab label={t("Presentations")} {...a11yProps(0)} />
                                <Tab label={t("Followers")} {...a11yProps(1)} />
                                <Tab label={t("Subscriptions")} {...a11yProps(2)} />
                                <Tab label={t("Library")} {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tab} index={0}>
                            <ScrollableFetcher loading={isPresenting} onFetch={() => setPresentationPage((page) => page + 1)} toggle={presentationPage} stop={presentations?.last_page == presentationPage}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                                    {presentations?.data.map((presentation, index) => (
                                        <Grid item xs={4} sm={4} md={3} lg={3} alignItems="stretch" key={index}>
                                            <PresentationBox {...presentation} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </ScrollableFetcher>
                            <DataEmptyBox loading={isPresenting} length={presentations?.data.length}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={1}>
                            <ScrollableFetcher loading={followersing} onFetch={() => setFollowersPage((page) => page + 1)} toggle={followersPage} stop={followers?.last_page == followersPage}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                                    {followers?.data.map((follower, index) => (
                                        <Grid item xs={2} sm={4} md={3} lg={2} alignItems="stretch" key={index}>
                                            <UserBox {...follower} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </ScrollableFetcher>
                            <DataEmptyBox loading={followersing} length={followers?.data.length}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={2}>
                            <ScrollableFetcher loading={subscripting} onFetch={() => setSubscriptionsPage((page) => page + 1)} toggle={subscriptionsPage} stop={subscriptions?.last_page == subscriptionsPage}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                                    {subscriptions?.data.map((follower, index) => (
                                        <Grid item xs={2} sm={4} md={3} lg={2} alignItems="stretch" key={index}>
                                            <UserBox {...follower} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </ScrollableFetcher>
                            <DataEmptyBox loading={subscripting} length={subscriptions?.data.length}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={tab} index={3}>
                            <ScrollableFetcher loading={liLoading} onFetch={() => setLibraryPage((page) => page + 1)} toggle={libraryPage} stop={library?.last_page == libraryPage}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                                    {library?.data.map((presentation, index) => (
                                        <Grid item xs={4} sm={4} md={3} lg={3} alignItems="stretch" key={index}>
                                            <PresentationBox {...presentation} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </ScrollableFetcher>
                            <DataEmptyBox loading={liLoading} length={library?.data.length}/>
                        </CustomTabPanel>
                    </Box>
                </Card>
            </Box>
            <Dialog open={editmode} onClose={closeEditMode} maxWidth={'md'} fullWidth>
                <DialogTitle>Subscribe</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{mb: "1rem"}}>{t('Edit your profile anytime you want')}</DialogContentText>
                    <Stack sx={{mb: "1rem"}} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        <AvatarUploader src={liveWatch('avatar')} onUpload={(file) => setPersonal('avatar', file)}/>
                    </Stack>
                    <TextField error={!!errors.name} helperText={errors.name?.message?.toString()} fullWidth id="name" label={t(!user.entreprise ? 'Fullname' : 'Company name')} variant="outlined" type="text" style={{marginBottom: "1.6rem"}} {...personal('name', {required: formSchema.name})}/>
                    <ActivitySelect fullWidth id="activity_id" renderInput={(params) => <TextField error={!!errors.activity_id} helperText={errors.activity_id?.message?.toString()} {...params} label={t('Activity')} {...personal('activity_id', {required: formSchema.activity_id})} onChange={() => {}} onBlur={() => {}} />} noOptionsText={t('No options')} loadingText={t('Loading')} style={{marginBottom: "1.6rem"}} onChange={(e, v) => setPersonal('activity_id', v as number)} value={liveWatch('activity_id')}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditMode}>{t('Cancel')}</Button>
                    <LoadingButton loading={updating} onClick={handleUpdate(update)} variant="contained">{t("Update")}</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}