import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PresentationBox from "../components/PresentationBox";
import { Helmet } from "react-helmet-async";
import { useGetHomePublicationsQuery } from "../api/appApi";
import { ScrollableFetcher } from "../components/ScrollableFetcher";

export const Home = () => {
    const [page, setPage] = useState<number>(1);
    const {data: presentations, isLoading, isFetching} = useGetHomePublicationsQuery(page);
    const { t } = useTranslation();
    const theme = useTheme();
    
    useEffect(() => {
        
    }, []);

    return (
        <>
        <Helmet>
            <title>{[t('Home'), t('app_name')].join(' - ')}</title>
        </Helmet>
        <Box sx={{marginBottom: "2rem", maxWidth: "82rem", mx: "auto"}}>
            <Box sx={{mb: "1rem"}}> 
                <Typography variant="h6" sx={{marginBottom: "1rem", fontWeight: "900"}} color={'primary.dark'} children={t('Discover')}/>
                <Box sx={{
                    backgroundImage: "url('/images/bg-box.jpg')",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "bottom",
                    backgroundSize: "cover",
                    borderRadius: theme.borders.primary,
                    p: "3rem 2rem"
                }}>
                    <Typography variant="h5" sx={{fontWeight: "bold", color: "white"}}>{t("United Experiences, Shared Stories: Create and share your journey with the world.")}</Typography>
                    <Typography variant="h6" sx={{fontWeight: "400", color: "white"}}>{t("Join us on this journey of life's stories")}</Typography>
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <ScrollableFetcher loading={isFetching} onFetch={() => setPage((page) => page + 1)} toggle={page} stop={presentations?.last_page == page}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                        {presentations?.data.map((presentation, index) => (
                        <Grid item xs={4} sm={6} md={4} lg={4} alignItems="stretch" key={index}>
                            <PresentationBox {...presentation}/>
                        </Grid>
                        ))}
                    </Grid>
                </ScrollableFetcher>
            </Box>
        </Box>
        </>
    );
}