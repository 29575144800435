import { createTheme } from '@mui/material/styles';
import { alpha, lighten, darken } from '@mui/material';
import '@mui/lab/themeAugmentation';

const themeColors = {
    primary: '#AF1905',
    secondary: '#000000',
    success: '#57CA22',
    warning: '#FFA319',
    error: '#FF1943',
    info: '#33C2FF',
    black: '#223354',
    white: '#ffffff',
    appgrey: '#EEF2F6',
    redcolor: '#DC2626',
    greencolor: '#0A875A',
    bluecolor: '#2563EB',
    pinkone: '#FF79A5',
    purplecolor: '#895889'
};

export const PureLightTheme = createTheme({
    typography: {
        fontFamily: [
            'Ubuntu',
            'sans-serif'
        ].join(','),
        menuCaption: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: 'primary.dark',
            padding: '6px',
            textTransform: 'capitalize',
            marginTop: '10px'
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 500,
            color: 'secondary.dark',
            textTransform: 'capitalize'
        },
        mainContent: {
            backgroundColor: 'transparent',
            width: '100%',
            minHeight: 'calc(100vh - 64px)',
            flexGrow: 1,
            padding: '20px',
            marginTop: '64px',
            marginRight: '20px'
        },
        commonAvatar: {
            cursor: 'pointer',
            borderRadius: '8px'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem'
        }
    },
    palette: {
        primary: {
            lighter: lighten(themeColors.primary, 0.95),
            light: lighten(themeColors.primary, 0.6),
            main: themeColors.primary,
            dark: darken(themeColors.primary, 0.2),
            contrastText: '#ffffff',
        },
        secondary: {
            lighter: lighten(themeColors.secondary, 0.95),
            light: lighten(themeColors.secondary, 0.8),
            main: themeColors.secondary,
            dark: darken(themeColors.secondary, 0.2),
            contrastText: '#ffffff',
        },
        appgrey: {
            lighter: lighten(themeColors.appgrey, 0.95),
            light: lighten(themeColors.appgrey, 0.8),
            main: themeColors.appgrey,
            dark: darken(themeColors.appgrey, 0.2),
            contrastText: '#895889',
        },
        redcolor: {
            lighter: lighten(themeColors.redcolor, 0.95),
            light: lighten(themeColors.redcolor, 0.8),
            main: themeColors.redcolor,
            dark: darken(themeColors.redcolor, 0.2),
            contrastText: '#895889',
        },
        greencolor: {
            lighter: lighten(themeColors.greencolor, 0.95),
            light: lighten(themeColors.greencolor, 0.8),
            main: themeColors.greencolor,
            dark: darken(themeColors.greencolor, 0.2),
            contrastText: '#895889',
        },
        bluecolor: {
            lighter: lighten(themeColors.bluecolor, 0.95),
            light: lighten(themeColors.bluecolor, 0.8),
            main: themeColors.bluecolor,
            dark: darken(themeColors.bluecolor, 0.2),
            contrastText: '#895889',
        },
        pinkone: {
            lighter: lighten(themeColors.pinkone, 0.95),
            light: lighten(themeColors.pinkone, 0.8),
            main: themeColors.pinkone,
            dark: darken(themeColors.pinkone, 0.2),
            contrastText: '#895889',
        },
        purplecolor: {
            lighter: lighten(themeColors.purplecolor, 0.95),
            light: lighten(themeColors.purplecolor, 0.8),
            main: themeColors.purplecolor,
            dark: darken(themeColors.purplecolor, 0.2),
            contrastText: '#895889',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    header: {
        height: "2rem"
    },
    aspectratio: {
        root: {
            position: 'relative',
            borderRadius: 'inherit',
            height: '0px',
            paddingBottom: 'calc(var(--AspectRatio-paddingBottom) - 2 * var(--variant-borderWidth, 0px))',
            overflow: 'hidden',
            transition: 'inherit',
            '--variant-borderWidth': '0px'
        },
        child: {
            display: 'flex',
            boxBack: 'center',
            justifyContent: 'center',
            boxAlign: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            margin: 0,
            padding: 0,
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => `
                body {
                    font-family: "${theme.typography.fontFamily}";
                }
            `
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        }
    },
    borders: {
        primary: "1rem",
        secondary: "0.5rem"
    }
});
