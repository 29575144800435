import { Link, Outlet } from "react-router-dom";
import "../css/auth-layout.css"
import { ValidationProvider } from "../providers/ValidationProvider";

export const AuthLayout = () => {
    return (
      <div id="auth">
        <header id="auth-header">
          <Link to={'/'}><img src="/assets/img/mapresentation-logo.png" alt="" /></Link>
        </header>
        <div id="auth-body" style={{marginBottom: "2rem"}}>
          <Outlet />
        </div>
      </div>
    );
}