import { Box, LinearProgress, Stack } from "@mui/material"

export const LoadingScreen = () => {
    return (<>
        <Stack sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "white",
            zIndex: 9999,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
            }}>
                <Box>
                    <Box component={'img'} sx={{height: 32}} src="/assets/img/mapresentation-logo.png" />
                    <LinearProgress />
                </Box>
        </Stack>
    </>)
}