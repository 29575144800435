import { createSlice } from '@reduxjs/toolkit'

interface CustomizationState {
  isOpen: any[];
  defaultId: string;
  fontFamily: string;
  borderRadius: number,
  opened: boolean
}

const initialState: CustomizationState = {
  isOpen: [],
  defaultId: 'default',
  fontFamily: "KidkidFont",
  borderRadius: 8,
  opened: true
};

const customizationSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
      
    }
})

export const { } = customizationSlice.actions
export default customizationSlice.reducer