import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { Author } from "../modules";
import { useTranslation } from "react-i18next";

const UserBox = (profile: Author) => {
    const theme = useTheme();
    const { t } = useTranslation();
    
    return (
        <Box component={Link} to={`/u/@${profile.slug}`} sx={{display: "flex", flexDirection: "column", alignItems: "center", textDecoration: "none", textAlign: "center", bgcolor: "white", borderRadius: theme.borders.primary, px: "1rem", py: "2rem"}}>
            <Avatar src={profile.avatar || undefined} alt={profile.name} />
            <Box sx={{py: "0.4rem"}}>
                <Typography variant="h6" sx={{marginBottom: "0.4rem", fontWeight: "600", fontSize: "0.8rem", textTransform: "capitalize"}} color={'black'} children={profile.name} noWrap/>
                <Typography variant="body2" sx={{fontWeight: "400", fontSize: "0.8rem", textTransform: "capitalize"}} color={'darkgrey'}>{profile.followers} {t("Followers")}</Typography>
            </Box>
        </Box>
    )
}

export default UserBox;