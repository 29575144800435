import { Card, Box, Avatar, Divider, useTheme, Typography, IconButton, InputAdornment, OutlinedInput, CircularProgress, FormControl, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Comment, PaginationMeta, Sort } from "../modules";
import { SendRounded } from "@mui/icons-material";
import CommentItem from "./CommentItem";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { useCommentPublicationMutation, useGetCommentsQuery } from "../api/appApi";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollableFetcher } from "./ScrollableFetcher";

export interface CommentSectionProps {
    slug: string;
    author?: string;
}

const CommentSection = ({slug, author} : CommentSectionProps) => {
    const [args, setArgs] = useState<{page: number, sort: Sort}>({page: 1, sort: 'desc'});
    const [page, setPage] = useState<number>(1);
    const [sortby, setSortBy] = useState<'asc' | 'desc' | 'replies'>('desc');
    const {data: comments, isLoading: loading, isFetching} = useGetCommentsQuery({slug, page: args.page, sort: args.sort});
    const [comment, setComment] = useState('');
    const [finished, setFinished] = useState<boolean>(false);
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const user = useAppSelector((state: RootState) => state.app.user);
    const [addComment, {isLoading: commenting}] = useCommentPublicationMutation();
    const location = useLocation();
    const navigate = useNavigate();

    const sendComment = () => {
        if( comment && !commenting ) {
            addComment({slug, content: comment}).unwrap().then(() => {
                setComment('');
            }).catch(() => {

            })
        }
    }

    useEffect(() => {
        //getComments();
        return () => {
            setFinished(false);
        }
    }, [slug]);

    return(
        <Card sx={{maxWidth: "100%", marginInline: 'auto', padding: "1rem", borderRadius: theme.borders.primary, marginBottom: "1rem"}}>
            <Box sx={{display: "flex", flexDirection: "row", width: "100%"}}>
                <Avatar alt={user.name} src={user.avatar || undefined} sx={{width: "3rem", height: "3rem", flex: "0 1 auto"}}/>
                <Box sx={{paddingInlineStart: "1rem", flex: 1}}>
                <OutlinedInput
                    fullWidth
                    placeholder={t("Add feedback")}
                    multiline
                    type={'text'}
                    onClick={() => {
                        if(!user.id) {
                            navigate('/auth/login', {state: {from: location}})
                        }
                    }}
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    endAdornment={
                        user.id ? <InputAdornment position="end">
                            <IconButton onClick={sendComment}
                                edge="end"
                            >
                                {commenting ? <CircularProgress size={'1.6rem'} /> : <SendRounded />}
                            </IconButton>
                        </InputAdornment> : ''
                    }/>
                </Box>
            </Box>
            <Divider sx={{my: "1rem"}}/>
            <ScrollableFetcher loading={isFetching} toggle={page} onFetch={() => {setArgs((a) => ({...a, page: a.page + 1}))}} stop={comments?.last_page == args.page}>
                <Box sx={{marginBottom: "1rem"}}>
                    <Box sx={{display: "flex", flexDirection: "row", marginBottom: "1rem"}}>
                        <Typography sx={{fontWeight: 800, fontSize: "0.8rem"}}>{comments?.total || 0} {t("feedbacks")}</Typography>
                        <FormControl sx={{ marginInlineStart: "auto", minWidth: 120, padding: 0 }} size="small">
                            <Select
                                variant="standard"
                                sx={{padding: 0, fontWeight: 800, fontSize: "0.8rem"}}
                                value={args.sort}
                                onChange={(s) => setArgs({page: 1, sort: s.target.value as any})}
                                displayEmpty
                                size="small"
                            >
                                <MenuItem value={'desc'}>{t('Newest feedbacks')}</MenuItem>
                                <MenuItem value={'asc'}>{t('Oldest feedbacks')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{marginBottom: "1rem"}}>
                        {!!comments?.data.length && comments.data.map((c, i) => (
                            <CommentItem key={`comment-item-${c.id}-${i + 1}`} slug={slug} author={author} comment={c} />
                        ))}
                    </Box>
                </Box>
            </ScrollableFetcher>
        </Card>
    )
}

export default CommentSection;