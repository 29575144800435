import { Box, CircularProgress, SxProps } from "@mui/material"
import { ReactNode, useEffect, useRef } from "react";

export interface ScrollableFetcherProps {
    loading: boolean;
    onFetch: () => void;
    toggle: any;
    sx?: SxProps;
    stop?: boolean;
    children: ReactNode;
}

export const ScrollableFetcher = ({loading, onFetch, toggle, sx, stop, children} : ScrollableFetcherProps) => {
    const element = useRef<HTMLElement>(null);

    const isBottom = (el:HTMLElement) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    useEffect(() => {
        const onScroll = () => {
            if (element.current && isBottom(element.current) && !loading && !stop) {
                onFetch();
            }
        };
        document.addEventListener("scroll", onScroll);

        return function () {
          document.removeEventListener("scroll", onScroll);
        };
    }, [toggle, loading]);

    return (<Box sx={sx} ref={element}>
        <Box>{children}</Box>
        {loading &&
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: "1rem"}}>
            <CircularProgress color="primary" size={"1.6rem"} />
        </Box>}
    </Box>)
}