import { useState } from "react";
import { useGetSidePublicationsQuery } from "../api/appApi"
import { ScrollableFetcher } from "./ScrollableFetcher"
import { Box } from "@mui/material";
import PresentationItem from "./PresentationItem";

export interface SidePresentationsProps {
    slug: string;
}

export const SidePresentations = ({slug} : SidePresentationsProps) => {
    const [page, setPage] = useState(1);
    const {data: presentations, isFetching} = useGetSidePublicationsQuery({slug, page}, {
        skip: !slug
    });
    return <ScrollableFetcher loading={isFetching} toggle={page} onFetch={() => setPage((page) => page + 1)} stop={presentations?.last_page == page}>
        {presentations?.data.map((presentation, index) => (
            <Box key={index} sx={{marginBottom: "1rem"}}>
                <PresentationItem {...presentation}/>
            </Box>
        ))}
    </ScrollableFetcher>
}