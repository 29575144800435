import { Box, CssBaseline } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { Header } from "../components/AppLayout/Header";
import { Sidebar } from "../components/AppLayout/SideBar";
import { SidebarProvider } from "../providers/SidebarProvider";
import { Main } from "../components/AppLayout/Main";
import { UploadProvider } from "../providers/UploadProvider";

export const AppLayout = () => {
  const theme = useTheme();
  
  return (
    <>
      <UploadProvider>
        <SidebarProvider>
          <Box sx={{ display: 'flex', background: `white` }}>
            <CssBaseline />
            <Header/>
            <Sidebar />
            <Main>
              <Outlet />
            </Main>
          </Box>
        </SidebarProvider>
      </UploadProvider>
    </>
  );
}