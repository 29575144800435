import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface DataEmptyBoxProps {
    label?: string;
    loading?: boolean;
    length?: number;
}
const DataEmptyBox = ({label, loading, length} : DataEmptyBoxProps) => {
    const { t } = useTranslation();
    if( loading || length ) {
        return <></>;
    }
    return (
        <Box sx={{maxWidth: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "1rem", textAlign: "center"}}>
            <Box component={'img'} sx={{maxWidth: "10rem"}} src="/assets/img/not-found.svg"></Box>
            <Box component={'h1'} sx={{color: "black"}}>{label ? label : t("Data not available")}</Box>
        </Box>
    )
}

export default DataEmptyBox;