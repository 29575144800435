import { Box, Avatar, useTheme, IconButton, InputAdornment, OutlinedInput, CircularProgress, Paper, Link as MuiLink, MenuItem, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { Comment, Sort } from "../modules";
import { SendRounded, SubdirectoryArrowRightOutlined, TurnLeftOutlined, MoreHorizOutlined } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { parseStringHtml } from "../helpers";
import Moment from "react-moment";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store";
import { useConfirm } from "material-ui-confirm";
import { useDeleteCommentMutation, useEditCommentMutation, useGetRepliesQuery, useReplyCommentMutation } from "../api/appApi";


export interface CommentItemProps {
    slug: string;
    author?: string;
    comment: Comment;
}

const CommentItem = ({slug, author, comment} : CommentItemProps) => {
    const confirm = useConfirm();
    const thisComment = useRef<HTMLElement>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const user = useAppSelector((state: RootState) => state.app.user);
    const [show, setShow] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [page, setPage] = useState(1);
    const [replyText, setReplyText] = useState('');
    const [commentText, setCommentText] = useState('');
    const [reply, setReply] = useState<boolean>(false);
    const [deleteFeedback, {isLoading: deleting, isSuccess: deleted}] = useDeleteCommentMutation();
    const [editFeedback, {isLoading: editing, isSuccess: edited}] = useEditCommentMutation();
    const [replyFeedback, {isLoading: replying}] = useReplyCommentMutation();
    const {data: replies, isFetching} = useGetRepliesQuery({...comment, slug, page}, {
        skip: !show
    });
    const count = replies?.total ? replies.total - (replies.per_page * page) : 0;
    const location = useLocation();
    const navigate = useNavigate();

    const handleDelEl = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseEl = () => {
        setAnchorEl(null);
    };

    const handleReply = () => {
        if(!user.id) {
            navigate('/auth/login', {state: {from: location}})
            return;
        }
        setReply(true);
    }

    const toEdittComment = () => {
        setCommentText(comment.content);
        setEdit(true);
        handleCloseEl();
    }

    const deleteComment = () => {
        confirm({
            title: t("Confirm?"),
            description: t("Are you sure you want to delete this feedback"),
            confirmationText: t("Yes"),
            cancellationText: t("Cancel")
        }).then(() => {
            deleteFeedback(comment).unwrap().then(() => {
            }).catch(() => {

            })
        }).catch(() => {})
    }

    const editComment = () => {
        editFeedback({...comment, comment: commentText}).unwrap().then((newComment) => {
            setCommentText('');
            setEdit(false);
        }).catch(() => {})
    }

    const replyComment = () => {
        replyFeedback({...comment, content: replyText, slug}).unwrap().then(() => {
            setReplyText('');
            setReply(false);
            setShow(true);
        }).catch(() => {})
    }

    return (
        <Box ref={thisComment} sx={{display: "flex", flexDirection: "row", marginBottom: "2rem", opacity: deleted ? 0.3 : 1}}>
            <Box component={Link} to={`/u/@${comment.user?.slug}`}>
                <Avatar alt={comment.user?.name} src={comment.user?.avatar} sx={{width: "3rem", height: "3rem"}} />
            </Box>
            <Box sx={{flex: "0 1 auto", width: "100%", paddingInlineStart: "1rem"}}>
                <Box sx={{marginBottom: "0.6rem", display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <MuiLink sx={{color: "primary", fontWeight: "400", textTransform: "capitalize", fontSize: "0.8rem", textDecoration: "none", marginInlineEnd: "0.6rem"}} component={Link} to={`/u/@${comment.user?.slug}`}>
                        {author == comment.user?.slug ? 
                        <Box component={'span'} sx={{px: "0.4rem", py: "0.2rem", bgcolor: 'primary.main', color: "white", borderRadius: "0.4rem"}}>{comment.user?.name}</Box> : 
                        <Box component={'span'} sx={{px: "0.4rem", py: "0.2rem"}}>{comment.user?.name}</Box>}
                    </MuiLink>
                    {deleting ? <Box sx={{fontSize: "0.6rem"}}>{t("Deleting...")}</Box> : <Moment style={{fontSize: "0.8rem"}} fromNow locale={i18n.language}>{comment.commented_at}</Moment>}
                    {comment?.user?.slug == user.slug && <Box sx={{marginInlineStart: "auto"}}>
                        <MoreHorizOutlined
                            fontSize="small"
                            onClick={handleDelEl}
                            sx={{
                                color: 'primary.main',
                                cursor: 'pointer'
                            }}
                            aria-controls="menu-popular-card"
                            aria-haspopup="true"
                        />
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseEl}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <MenuItem onClick={deleteComment}>{t("Delete")}</MenuItem>
                            <MenuItem onClick={toEdittComment}>{t("Edit")}</MenuItem>
                        </Menu>
                    </Box>}
                </Box>
                <Paper sx={{bgcolor: 'appgrey.main', padding: "1rem", borderRadius: theme.borders.primary}} elevation={0}>
                    {!edit && <Box component={'div'}>{parseStringHtml(comment.content)}</Box>}
                    {edit && <>
                        <OutlinedInput
                            fullWidth
                            placeholder={t("Edit feedback")}
                            multiline
                            type={'text'}
                            onChange={(e) => setCommentText(e.target.value)}
                            value={commentText}
                            endAdornment={
                                user.id ? <InputAdornment position="end">
                                    <IconButton onClick={editComment}
                                        edge="end"
                                    >
                                        {editing ? <CircularProgress size={'1.6rem'} /> : <SendRounded />}
                                    </IconButton>
                                </InputAdornment> : ''
                            }/>
                        <MuiLink component={'button'} color={'appgrey.darker'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setEdit(false)}>{t("Cancel")}</MuiLink>
                    </>}
                </Paper>
                <Box sx={{textAlign: "start", paddingTop: "0.6rem", display: "flex", flexDirection: "row", marginBottom: "0.6rem"}}>
                    {!!comment.replies && <><MuiLink component={'button'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setShow(!show)}>
                        {show ? <><TurnLeftOutlined sx={{fontSize: "0.8rem"}} /> {t("Hide replies")}</>: <><SubdirectoryArrowRightOutlined sx={{fontSize: "0.8rem"}} /> {t("View replies")}</>} ({comment.replies})
                        </MuiLink><Box sx={{mx: "0.4rem"}}></Box></>}
                    <MuiLink component={'button'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => handleReply()}>{t("Reply")}</MuiLink>
                    <Box sx={{mx: "0.4rem"}}></Box>
                    {reply && <MuiLink component={'button'} color={'appgrey.darker'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setReply(false)}>{t("Cancel")}</MuiLink>}
                </Box>
                {reply && <Box sx={{marginBottom: "1rem"}}>
                    <OutlinedInput
                        fullWidth
                        placeholder={t("Reply...")}
                        multiline
                        type={'text'}
                        onChange={(e) => setReplyText(e.target.value)}
                        value={replyText}
                        endAdornment={
                            user.id ? <InputAdornment position="end">
                                <IconButton onClick={replyComment}
                                    edge="end"
                                >
                                    {replying ? <CircularProgress size={'1.6rem'} /> : <SendRounded />}
                                </IconButton>
                            </InputAdornment> : ''
                        }/>
                </Box>}
                {show && <>
                    {replies?.data?.map((r, i) => (
                        <CommentItem key={`comment-reply-${r.id}-${i + 1}`} slug={slug} author={author} comment={r}/>
                    ))}
                </>}
                {isFetching &&
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", mt: "1rem"}}>
                    <CircularProgress color="primary" size={"1.6rem"} />
                </Box>}
                {count > 0 && <Box>
                    <><MuiLink component={'button'} underline="none" sx={{fontSize: "0.8rem"}} onClick={() => setPage((page) => page + 1)}>{t("More replies")} ({count})</MuiLink><Box sx={{mx: "0.4rem"}}></Box></>
                </Box>}
            </Box>
        </Box>
    );
}

export default CommentItem;